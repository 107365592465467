

.std-header {

	.tablet({

	});
	
	.logo {
		color: white;
		text-decoration: none;
		img {
			max-width: 80%;
		}
	}
	
	.menu, .top-menu {
		list-style: none;
		justify-self: end;
		li {
			margin-left: 10px;
			a {
				.fs(20);
				text-transform: uppercase;
				text-decoration: none;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
		& > li:hover {
			& > a {
				color: black;
			}
		}
	}

	nav {
		background-color: white;
	}
	.desktop({
		display: grid;
		grid-template-columns: auto 1fr;
	
		nav {
			display: grid;
			grid-template-columns: auto 1fr;
			.logo {
				grid-row: span 2;
				align-self: center;
			}

		}
		
		.top-menu {
			margin: 0;
			padding: 0;
			justify-self: end;
			align-self: end;
			li {
				display: inline-block;
				a {
					text-decoration: none;
				}
			}
			
			.social {
				a {
					display: inline-block;
					margin-bottom: -3px;
					img {
						display: block;
					}
				}
			}

		}
		.menu {
			margin: 0;
			padding: 0;
			align-self: start;
			text-align: right;
			li {
				display: inline-block;
				position: relative;
				a {
					.fs(17);
					text-decoration: uppercase;
					color: @tan-text;
					font-weight: 700;
				}
				
				&.current {
					a {
						color: black;
					}
				}
				
				ul {
					position: absolute;
					display: none;
					top: 100%;
					// left: 0;
					right: 0px;
					padding-top: 42px;
					padding-left: 0;
					margin: 0;
					width: 280px;

					li {
						display: block;
						padding: 10px 15px;
						background-color: @box-back;
						
						&:first-child {
							padding-top: 15px;
						}
						&:last-child {
							padding-bottom: 15px;
						}
						
						&:hover {
							background-color: @salmon;
						}
					}
					
					a {
						display: block;
						color: black;
						font-weight: 300;
						text-transform: none;
						white-space: nowrap;
						text-align: left;
					}
				}
				
				&:hover {
					ul {
						display: block;
						
					}
				}
				
				&:last-child {
					a {
						padding-right: 0;
					}
				}
				
			}
		}
		.tribar, .close {
			display: none;
		}
	});
	.desktop-and-smaller({
		.left-mark {
			display: none;
		}
		.header-staff-right-picture {
			display: none;
		}
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 15px;
			box-sizing: border-box;
			background-color: black; 
			.menu {
				padding: 0;
			}
			
			a {
				color: black;
			}
			ul {
				list-style: none;
			}
			
			& > li {
				display: block;
				text-align: center;
				
				& > a {
					text-align: center;
				}
				
				& > ul {
					position: relative;
					left: auto;
					opacity: 1;
					text-align: center;
					
					a {
						text-align: center;
					}
				}
			}
			
			&.open {
				left: 0;
				opacity: 1;
			}
			.top-menu {
				padding-left: 0;
				li {
					display: inline-block;
					&:after {
						display: none;
					}
				}
				.social {
					display: block;
					margin-top: 10px;
					margin-left: 12px;
					a:first-child {
						padding-left: 0;
					}
				}
			}
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			
			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: black;
			}
			.two {
				margin: 7px 0;
			}
			
		}
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
			font-size: 40px;
		}
	});

}

header {
	.std-header;
	z-index: 10;
}
.inner-header {
	display: grid;
	grid-template-columns: minmax(auto, 1fr) minmax(auto, @container-width) minmax(0, 1fr);
	
	.mobile-logo {
		display: none;
	}
	.left-mark {
		img {
			float: right;
			padding-left: 20px;
		}
	}
	
	.right-mark {
		background-color: white;
	}
	
	.header-staff-right-picture img {
		display: block;
	}
	
	nav {
		background-color: white;
		
	}
	
	.top-menu {
		padding-bottom: 20px;
		a {
			.fs(16);
			color: black;
			text-transform: none;
		}
		
		a + a {
			padding-left: 0;
			&:last-of-type {
				padding-right: 0;
			}
		}
		
		
		.long {
			margin-left: 0;
			&:after {
				content: '|';
				vertical-align: baseline;
				color: black;
			}
		}
		
	}
	.desktop({
		border-right: 20px solid white;
	});
	.desktop-and-smaller({
		background-color: white;
		.mobile-logo {
			display: block;
			padding: 10px 20px;
			img {
				max-width: 240px;
			}
		}

	});
}
body .page-inner .jumbo  .inner-header {
	z-index: 3;
}
.desktop-and-smaller({
	body .page-inner .jumbo  .inner-header {
		.left-mark {
			display: none;
		}

	}
});


.jumbo {
	background-size: cover;
	h1 {
		.cormorant;
		color: white;
		margin-bottom: 0;
		margin-top: 0;
		text-transform: uppercase;
		line-height: .8em;
		font-weight: 300;
	}
	h2 {
		.fs(26);
		color: white;

	}
}

.page-landing {
	.jumbo {
		position: relative;
		.msg {
			padding-bottom: 120px;
			padding-top: 10vh;
			h1 {
				// .fs(41);
				.fs(46);
				line-height: 1em;
				font-weight: 200;

				strong {
					display: block;
					// .fs(75);
					.fs(85);
					mix-blend-mode: plus-lighter;
					opacity: .8;
					line-height: .9em;
					margin-top: 20px;
					color: @yellow;
					opacity: .9;
					font-weight: 800;
					
					.ib {
						// .fs(42);
						.fs(48);
						line-height: 1em;
					}
				}
			}
			
			.inner {
				position: absolute;
				bottom: 30px;
			}
		}
		// h1 {
		// 	.fs(108);
		// }
		// h2 {
		// 	.fs(21);
		// 	font-weight: 400;
		// }
		
		h1, h2 {
			.jost;
			max-width: 50%;
		}
		
		.lin-homepage-banner-picture {
			img {
				object-position: top center;
			}
		}
		
		.video-holder {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: -1;
			overflow: hidden;
			&:after {
				content: '\00a0';
				position: absolute;
				background-color: rgba(0,0,0,.4);
  				background: linear-gradient(to right, rgba(0,0,0,.2), rgba(0,0,0,.0)); /* Standard syntax */
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
		video {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			z-index: -1;
		}
		.msg {
			overflow: hidden;
		}
		
		.box-button {
			display: inline-block;
			padding: 10px 20px;
		}

		min-height: 450px;
		// .tablet-small({
		// 	min-height: 550px;
		// });

		.tablet({
			overflow: hidden;
			min-height: 650px;
		});

		.desktop({
			video {
				width: 100%;
				height: auto;
			}
			.msg {
				h1 {
					strong {
						.ib {
							margin-top: -40px;
						}
					}
				}
			}
		});
		
		@media  (min-width: 1920px) {
			aspect-ratio: 2.5;	
		}

		.desktop-and-smaller({
			.header-staff-left-picture {
				display: none;
			}
		});
		.tablet-small-and-smaller({
			.msg {
				h1 {
					font-size: 5vw;
					strong {
						font-size: 10vw;
						.ib {
							font-size: 7vw;
						}
					}
				}
				h2 {
					.fs(20);
				}

			}
		});
		
	}
	.coming-events-wrapper {
		padding-left: @page-side-padding * 2;
		padding-right: @page-side-padding * 2;
	}

	.coming-events {
		margin-bottom: 40px;
		h1 {
			text-transform: uppercase;
			.cormorant;
			.fs(30);
			text-align: center;
			em {
				.fs(25);
				.open-sans;
				display: block;
				font-weight: 400;
				text-transform: none;
				color: @tan-text;
				font-style: normal;
			}
		}
		
		.all-events {
			text-align: center;
			a {
				.hollow-button;
				margin-left: 20px;
				margin-right: 20px;
			}

		}
	}
	.event-tiles {
		text-align: center;
		.event-tile {
			display: inline-block;
			text-align: left;
			max-width: 28%;
			vertical-align: top;
			margin-left: 1%;
			margin-right: 1%;
		}
		
		.tablet-and-smaller({
			.event-tile {
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: 500px;
				max-width: 100%;
				margin-top: 50px;
			}
		});
	}
	.strip {
		display: grid;
		grid-template-columns: auto 1fr auto;
		.left, .right {
			align-self: center;
			a {
				display: inline-block;
				cursor: pointer;
				// border: solid 1px @green-box-background;
				padding: 5px;
				img {
					display: block;
				}
			}
		}
		
		
		.left {
			padding-left: 10px;
			padding-right: 10px;
		}
		.right {
			padding-right: 10px;
			padding-left: 10px;
		}
		.event-tile {
			display: inline-block;
			vertical-align: top;
			white-space: normal;
			max-width: 100%;
		}
		.tablet({
			.left {
				// padding-left: 80px;
			}
			.right {
				// padding-right: 80px;
			}
			.left, .right {
				a {
					padding: 20px;
				}
			}
			.event-tile {
				width: 304px;
				margin-right: 20px;
				// margin-right: 1.5%;
			}
		});
		.mid {
			overflow: hidden;
		}
		.stripe {
			white-space: nowrap;
		}	

	}
	
	.great-music-to-life {
		.tablet-small({
			.container {
				min-height: 300px;
			}
		});
		.tablet-small-and-smaller({
			.backer {
				display: none;
			}
		});
	}
	
	.concerts-on-demand {
		.tablet-and-smaller({
			grid-template-columns: 1fr;
			// margin-top: 20px;
			
			.img {
				min-height: 300px;
			}
			
			.msg {
				padding-left: 20px;
				padding-right: 20px;
				grid-row: 1;
			}
		});
	}
	
	.season-ticket-bar {
		padding-top: 30px;
		padding-bottom: 40px;
		background-color: @grey-box-back;
		text-align: center;
		.container {
			max-width: 656px;
		}
		
		h1 {
			text-transform: uppercase;
			.cormorant;
			.fs(43);
			
			small {
				display: block;
				text-transform: none;
				.open-sans;
				.fs(30);
				font-weight: 300;
			}
		}

		a {
			.hollow-button;
			background: transparent;
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	
}
.page-inner {
	.jumbo {
		position: relative;
		
		.music-note-in-header {
			position: absolute;
			height: 100%;
			width: 100%;
			overflow: hidden;
		}
		.inner-header, .msg {
			position: relative;
			z-index: 2;
			.inner {
				overflow: hidden;
			}
		}
		.msg {
			padding-bottom: 40px;
			padding-top: 120px;
			
		}
		h1 {
			.fs(50);
		}
		h2 {
			.fs(21);
			font-weight: 400;
		}
		
		.music-note-header-overlay-picture {
			img {
				position: absolute;
			}
		}
		
	}
}

.donation-details {
	.full-back;
	display: grid;

	
	.msg {
		.title {
			position: relative;
			padding: 50px @page-side-padding 20px @page-side-padding;
			.tablet({
				padding: 50px 30px 20px 0;
			});
			background-color: @blue-background;
			.elements {
				position: relative;
				z-index: 2;
				text-align: center;
				h1 {
					.cormorant;
					text-transform: uppercase;
					margin-top: 0;
					margin-bottom: 0;
				}
				
				.smaller {
					.fs(10);
					margin-top: 0;
				}
				
				.buttons {
					margin: 20px auto;
				}

				.box-button {
					display: inline-block;
					color: black;
					margin-left: 5px;
					margin-right: 5px;
					font-weight: 300;
					padding: 14px 20px;
					text-transform: none;
				}

			}
			&:after {
				content: '\00a0';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: -10000px;
				background-color: @blue-background;
			}
		}
		.details {
			p em {
				font-size: 85%;
			}
		}
		
	}
	.program-notes {
		background-color: transparent;
		margin-top: 20px;
		display: grid;
		h2 {
			color: @tan-text;
			font-weight: 400;
		}
		.tablet({
			grid-template-columns: 1fr 1fr;
			column-gap: 50px;
			
			& > h1 {
				grid-column: span 2;
			}
		});
		
		ul {
			list-style-image: url('/img/vert-line-half.png');
			padding-left: 15px;
			.txt {
				display: inline-block;
				vertical-align: top;
				margin-top: -5px;
			}
			
			li {
				margin-bottom: 12px;
			}
		}
		p a {
			color: @tan-text;
			font-weight: 600;
		}
	}
	
	.tablet({
		grid-template-columns: 1fr minmax(auto, @container-width * .8) minmax(auto, @container-width * .2) 1fr;
		
		.left {
			grid-column: 1;
		}
		
		.right {
			grid-column: 4;
		}
		.msg {
			grid-column: 2;
		}
		.pic {
			grid-column: 3;
			img {
				padding: 50px 0 50px 70px;
				max-width: 100%;
				box-sizing: border-box;
			}
		}
		.program-notes {
			grid-column: 2 / span 2;
		}

	});
}

.donation-info {
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;
}

.performance-details {
	display: grid;
	
	.left {
		min-width: @page-side-padding;
	}
	
	& > .msg {
		& > .title {
			position: relative;
			padding: 20px 30px 5px 0;
			background-color: @grey-box-back;
			.elements {
				display: grid;
				grid-template-columns: minmax(auto, 60%) auto;
				position: relative;
				z-index: 2;
				h1 {
					.cormorant;
					text-transform: uppercase;
					margin-top: 0;
					margin-bottom: 0;
					padding-top: 30px;
					padding-bottom: 30px;
				}
				.ticket-buttons {
					align-self: center;
					justify-self: right;
					text-align: right;
					a {
						.box-button;
						display: block;
						margin-top: 10px;
						margin-bottom: 10px;
						text-align: center;
					}
					.available-msg {
						text-align: center;
					}
				}
				
				.phone-and-smaller({
					display: block;
					.box-button {
						display: inline-block;
						margin-top: 20px;
						margin-bottom: 20px;
					}
				});

			}
			&:after {
				content: '\00a0';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: -10000px;
				background-color: @grey-box-back;
			}
		}
		.details {
			p em {
				font-size: 85%;
			}
		}
	}
	
	.tablet({
		grid-template-columns: 1fr minmax(auto, @container-width * .7) minmax(auto, @container-width * .3) 1fr;
		
		.left {
			grid-column: 1;
		}
		
		.right {
			grid-column: 4;
		}
		.msg {
			grid-column: 2;
		}
		.pic {
			grid-column: 3;
			img {
				display: block;
				padding: 50px 0 50px 70px;
				max-width: 100%;
				box-sizing: border-box;
			}
		}
		.program-notes {
			grid-column: 2 / span 2;
		}

	});
}

.concert-sponsors {
	// padding-top: 80px;
	padding-bottom: 80px;
	
	.tan-button {
		margin-right: 30px;
		margin-bottom: 20px;
	}
	
	.sponsors {
		.concert-sponsor {
			display: inline-block;
			padding-right: 40px;
			vertical-align: top;
			
			.role {
				font-weight: 600;
				border-bottom: @tan-text solid 2px;
			}
			img {
				display: block;
				max-width: 150px;
				max-height: 150px;
				margin-top: 15px;
			}

		}
	}
}

footer {
	background-color: @grey-box-back;
	padding-top: 70px;
	padding-bottom: 90px;
	
	.container.main-footer {
		display: grid;
		row-gap: 30px;
		
		.tablet({
			grid-template-columns: auto auto auto;
			column-gap: 30px;
		});
	}
	
	a.tan {
		text-decoration: none;
		color: @tan-text;
	}
	
	.brand {
		.fs(12);
		img {
			width: auto;
			max-width: 100%;
			height: auto !important;
		}
	}
	
	.possible {
		display: grid;
		grid-template-columns: auto auto auto;
		column-gap: 10px;
		.msg {
			align-self: center;
			.fs(12);
			font-style: italic;

		}
		
	}
	.full-width {
		text-align: center;
		box-sizing: border-box;
		margin-bottom: 20px;
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 200px;
			height: auto !important;
			max-width: 100%;
		}
	}
	
	.hours {
		align-self: start;
		h2 {
			margin-bottom: 0;
			margin-top: 0;
		}
		p {
			margin-top: 0;
		}
		p + p {
			margin-top: 30px;
			margin-bottom: 0;
		}
	}
	
	.tablet-and-smaller({
		.possible {
			grid-template-columns: 1fr 1fr;
			& > * {
				text-align: center;
				padding-left: 20px;
				padding-right: 20px;
				margin-top: 20px;
			}
			
			.msg {
				grid-column: span 2;
			}
			.land-legacy-sponsor-picture {
				grid-column: 2;
				grid-row: 1;
			}
		}
		.full-width {
			grid-column: 1 / span 2;
		}
	});
	.tablet({
		.full-width {
			grid-column: 1 / span 3;
		}

	});
	.sub-footer {
		.fs(12);
	}
	
	.social {
		margin-top: 15px !important;
		a {
			padding-right: 5px;
			text-decoration: none;
		}
	}

}

.edu-bring {
	.msg {
		h2 {
			color: @tan-text;
			strong {
				.fs(34);
				color: black;
			}
		}
	}
}

.about-jere {
	background-color: black;
	position: relative;
	// overflow: hidden; 
	padding-top: 60px;
	padding-bottom: 60px;
	
	.phone-jere {
		display: none;
	}
	
	a.h2 {
		.cormorant;
		.fs(20);
		color: @tan-text;
		text-decoration: none;
	}

	.desktop({
		margin-top: -90px;
		margin-right: ~"calc((100% - 1100px)/2)";
		.container {
			margin-left: ~"calc((100vw - 1100px)/2)";
		}
	});
	h1 {
		.cormorant;
		margin-bottom: 0;
		color: white;
		font-weight: 400;
		text-transform: uppercase;
	}
	h2 {
		.cormorant;
		font-weight: 400;
		color: @tan-text;
		margin-top: 0;
		margin-bottom: 50px;
	}
	
	.big-phone({
		position: relative;
		isolation: isolate;
		.lin-no-bar-picture {
			user-select: none;
			img {
				position: absolute;
				height: 100% !important;
				object-fit: cover;
				object-position: center center;
				z-index: -1;
				user-select: none;
				right: 0;
				top: 0;
			}
		}
		.lin-bar-picture {
			user-select: none;
			img {
				position: absolute;
				height: 105% !important;
				height: ~"calc(100% + 10px) !important";
				object-fit: cover;
				object-position: center center;
				z-index: -1;
				user-select: none;
				right: 30px;
				bottom: 0;
			}

		}
	});
	
	.backer {
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		.jere-mobile-picture img {
			display: none;	
		}
	}
	.halfer {
		position: relative;
		z-index: 2;
		color: white;
	}
	.tablet-and-smaller({
		.backer {
			.about-artistic-director-back {
				display: none;	
			}
			.jere-mobile-picture img {
				display: block;	
			}
		}
		.halfer {
			max-width: 400px;
		}
	});
	.big-phone-and-smaller({
		.lin-no-bar-picture, .lin-bar-picture {
			display: none;
		}
		.backer {
			display: none;
			.jere-mobile-picture img {
				display: none;	
			}
		}
		.halfer {
			max-width: none;
		}
		
		.phone-jere {
			display: block;
			max-width: 100%;
			margin: 70px auto 10px auto;
		}
	});
}

.our-history {
	h2 {
		font-size: 30px !important;
		color: @tan-text;
		line-height: 1em;
	}

	a.h2 {
		.cormorant;
		.fs(20);
		color: black;
		text-decoration: none;
	}
	
	.side-pic {
		position: relative;
	}
	.desktop({
		padding-bottom: 230px;
		.original-orchestra-picture img {
			position: absolute;
			width: 900px !important;
			max-width: ~"calc(50vw - 33px)" !important;
		}

	});
	
}

.auditions {
	padding-top: 10px;
	padding-bottom: 60px;
	background-color: @tan-text;
	h1 {
		color: white;
		text-transform: uppercase;
		.fs(50);
		.cormorant;
		text-align: center;
		font-weight: 300;
	}
	
	.groups {
		text-align: center;
		display: flex;
		justify-content: center;
		margin-left: -1%;
		margin-right: -1%;
		
		/*
		grid-template-columns: 1fr 1fr;
		column-gap: 60px;
		*/
		
		.group {
			position: relative;
			display: inline-block;
			background-color: black;
			color: white;
			padding: 20px;
			text-decoration: none;
			box-sizing: border-box;
			max-width: 510px;
			margin-left: 1%;
			margin-right: 1%;
			h2 {
				.fs(33);
				text-transform: uppercase;
				margin-top: 0;
				font-weight: 400;
			}
			
			.tablet({
				max-width: 49%;

			});
		}
		a {
			color: white;
		}
		

		a.arrow {
			text-decoration: none;
		}
		
		.go-arrow-picture img {
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}
	
	.tablet-and-smaller({
		.groups {
			display: block;
		}
		.group {
			margin-bottom: 20px;
		}
	});
}

.roster-selector {
	margin-top: 80px;
	margin-bottom: 80px;
	.container {
		.tablet ({
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 60px;
		});
	}
	
	.group-selector {
		margin-top: 70px;
		.tablet({
			margin-top: 0;
		});
		.top {
			img {
				width: 100%;
			}
		}
		
		a {
			text-decoration: none;
			color: black;
		}
		
		h2 {
			.fs(20);
			.cormorant;
			color: black;
			text-transform: uppercase;
			margin-bottom: 5px;

			strong {
				display: block;
				.fs(40);
				color: @tan-text;
			}
		}
		h3 {
			margin-top: 5px;
			.fs(30);
			font-weight: 600;
		}
	}
}

.board-of-directors {
	padding-top: 30px;
	padding-bottom: 60px;
	h1 {
		text-transform: uppercase;
		.cormorant;
	}
	
	.section {
		display: inline-block;
		padding-right: 60px;
		vertical-align: top;
		h3 {
			color: @tan-text;
			text-transform: uppercase;
			font-weight: 400;
			margin-bottom: 4px;
		}
		h3 + p {
			margin-top: 4px;
		}
	}

}

.musician-sponsorship {
	h1 {
		margin-bottom: 50px;
	}
	
	strong {
		color: @tan-text;
	}
	

	.box-button {
		display: inline-block;
		text-transform: none;
		padding: 16px 20px;
		font-weight: 600;
		
		&.reversed {
			background-color: @tan-text;
			color: white;
		}
		
		&.stack-button {
			margin-bottom: 10px;
			min-width: 300px;
			text-align: center;
			background-color: transparent;
			display: inline-block;
			margin-bottom: 20px;
			border: solid 1px @tan-text;
			color: white;
			font-weight: 600;
		}
	}
	.normal-links {
		a {
			color: @tan-text;
			text-decoration: none;
			font-weight: 600;
		}
	}
}

.corporate-sponsorship {
	.desktop({
		grid-template-columns: 1fr (@container-width * .7) (@container-width * .3) 1fr;

	});
	.msg {
		padding-top: 40px;
		padding-bottom: 40px;
		padding-right: 50px;
		
		h1 {
			.fs(24);
			color: @tan-text;
			margin-bottom: 60px;
			
			strong {
				.cormorant;
				.fs(40);
				color: black;
			}
		}
	}
	.tan {
		color: @tan-text;
	}
	a {
		text-decoration: none;
		font-weight: 600;
		color: black;
	}
	ul {
		columns: 2;
	}
	
}

.more-ways-to-give {
	.full-back;
	padding-top: 40px;
	padding-bottom: 60px;
	text-align: center;
	h1 {
		.cormorant;
		.fs(50);
		text-transform: uppercase;
	}
	.giving {
		margin-bottom: 40px;
		.give {
			color: @tan-button-back;
			display: inline-block;
			margin: 20px 20px;
		}
	}
	
	a {
		color: black;
		font-weight: 600;
		text-decoration: none;
	}

}

.contact-section {
	h3 {
		margin-top: 22px;
		margin-bottom: 0;
		color: @tan-text
	}
	h3 + p {
		margin-top: 0;
	}
	
	p {
		strong {
			color: black !important;
		}
	}
	
	a {
		text-decoration: none;
	}
	.contact {
		a {
			color: @tan-text;
		}
		
		a:last-of-type {
			color: black;
		}
	}
}
.rs-user-form {
	margin-bottom: 30px;

	form {
		label {
			margin-bottom: 20px;
			display: block;
			font-weight: 600;
			
		}
		input[type=text] {
			display: block;
			width: 100%;
			box-sizing: border-box;
			padding: 3px 7px;
			border: solid 1px @tan-text;
			margin-top: 3px;
		}
		select {
			display: block;
			width: 100%;
			box-sizing: border-box;
			padding: 3px 7px;
			border: solid 1px @tan-text;
			margin-top: 3px;
			background-color: white;
		}
		textarea {
			display: block;
			width: 100%;
			box-sizing: border-box;
			padding: 3px 7px;
			border: solid 1px @tan-text;
		}
		
		button[type=submit] {
			display: inline-block;
			background-color: @tan-text;
			color: white;
			border: 0;
			padding: 10px 20px;
			font-weight: 600;
		}
		label.indented {
			padding-left: 30px;
		}
		
		label.hidden {
			display: none;
		}
		
		em {
			font-weight: 400;
		}
		
		.resume-label {
			input {
				position: absolute;
				margin-left: -10000px;
			}
		}
	}
}

.program-event {
	h2 {
		font-weight: 300;
	}
}

.sub-header-message {
	padding-top: 20px;
	padding-bottom: 20px;
}

.orchestra-musicians-top {
	padding-top: 60px;
	h1 {
		.cormorant;
		text-transform: uppercase;
		.tan {
			color: @tan-text;
		}
	}
}
.orchestra-musicians {
	display: grid;
	column-gap: 10px;
	padding-top: 60px;
	padding-bottom: 60px;
	h4 {
		margin-bottom: 0;
		color: @tan-text;
	}
	
	h4 + br {
		display: none;
	}
	.indent {
		display: inline-block;
		padding-left: 15px;
	}

	.tablet({
		grid-template-columns: repeat(2, 1fr);
	});
	.desktop({
		grid-template-columns: repeat(3, 1fr);
	});
}

.performance-date-line + .performance-date-line {
	margin-top: 10px;
}


.page-tickets-and-events {
	.performance-details {
		.msg {
			.title {
				padding-top: 20px;
				.tablet-and-smaller({
					padding-left: @page-side-padding;
				});
				.elements {
					display: block;
					a {
						display: inline-block;
						margin-right: 20px;
						text-transform: none;
						font-weight: 600;
						padding: 10px 45px;
						margin-bottom: 15px;
					}
					
					.tickets-available-soon {
						// padding-left: @page-side-padding;
						display: inline-block;
					}

				}
			}
		}
		.button-box {
			justify-self: left;
		}
	}
}

.page-ticket-for-event {
	.performance-details {
		.concert-sponsor {
			border-left: 5px solid @tan-text;
			padding-left: 10px;
			.name {
				color: @tan-text;
				font-weight: 600;
			}
		}
		
		.featured-musician {
			display: inline-grid;
			grid-template-columns: auto 1fr;
			column-gap: 30px;
			margin-top: 20px;
			margin-bottom: 20px;

			.musician-pic {
				img {
					max-width: 170px;
					display: block;
					border-radius: 2px;
					border: solid 2px @tan-text;
				}
				.name {
					.fs(14);
					margin-top: 10px;
					white-space: nowrap;
				}
			}
			
			.info {
				max-width: 300px;
				align-self: center;
			}
		}
		

	}
	.tablet-and-smaller({
		.performance-details {
			.title, .details, .program-notes h3 {
				padding-left: 15px;
				padding-right: 15px;
			}
			.pic {
				padding-top: 40px;
				padding-bottom: 40px;
				img {
					display: block;
					width: 100%;
					max-width: 500px;
					margin: auto;
					box-sizing: border-box;

				}
			}
			
			.featured-musician {
				margin-left: 15px;
				margin-right: 15px;
			}
		}
	});
}

.featured-musicians-details {
	.featured-musician {
		display: inline-grid;
		grid-template-columns: auto 1fr;
		column-gap: 30px;
		margin-top: 20px;
		margin-bottom: 20px;

		.musician-pic {
			img {
				max-width: 170px;
				display: block;
				border-radius: 2px;
				border: solid 2px @tan-text;
			}
			.name {
				.fs(14);
				margin-top: 10px;
				white-space: nowrap;
			}
		}
		
		.info {
			// max-width: 300px;
			align-self: center;
			
			p:first-child {
				margin-top: 0;
			}
		}
	}
	.tablet-and-smaller({
		.featured-musician {
			margin-left: 15px;
			margin-right: 15px;
		}
	});

}

.page-program-for-event {
	.roster-musicians {
		display: grid;
		align-items: center;
		justify-items: left;
		.editing-page & {
			display: block;
		}
		a {
			text-decoration: none;
			color: inherit;
			padding-left: 40px;
			padding-right: 40px;
		}
		.name {
			.cormorant();
			.fs(35);
			margin-top: 10px;
			white-space: nowrap;
		}
		.role {
			.fs(16);
			color: @tan-text;
			font-weight: 600;
		}
		
		.info {
			align-self: center;
		}
		h3 {
			text-decoration: underline;
		}
		
		.tablet({
			grid-template-columns: repeat(2, 1fr);
		});
	}
	.tablet-and-smaller({
		.performance-details {
			.title, .details, .program-notes h3 {
				padding-left: 15px;
				padding-right: 15px;
			}
			.pic {
				display: none;
				padding-top: 40px;
				padding-bottom: 40px;
				img {
					display: block;
					max-width: 100%;
					width: 500px;
					margin: auto;
				}
			}
		}
	});
	
	.details {
		.premium-program-advertisement {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
	
	.program-notes {
		margin-bottom: 20px;
		.content {
			padding-bottom: 1px;
		}
		
		.music-note {
			.clear-inner;
			margin: 20px;
			.piece {
				.cormorant();
				.fs(35);
				margin-bottom: 0;
				p {
					margin-bottom: 0;
					
				}
			}
			.composer {
				.fs(16);
				color: @tan-text;
				font-weight: 600;
			}
			.header {
				// display: none;
			}
			.music-desc {
				h1 {
					.cormorant();
					.fs(30);
					margin-bottom: 0;
				}
				h2 {
					.fs(20);
					color: @tan-text !important;
					margin-top: 0;
				}
			}
		}
		.featured-musician {
			margin: 20px;
			display: grid;
			column-gap: 30px;
			.musician-pic {
				img {
					max-width: 140px;
				}
			}
			.name {
				.cormorant();
				.fs(35);
			}
			.role {
				.fs(16);
				color: @tan-text;
				font-weight: 600;
			}
			
			.big-phone({
				grid-template-columns: auto 1fr;

			});
		}
	}
	
	.program-notes + .premium-program-advertisement {
		.tablet({
			grid-column: 2;
		});
		margin-top: 0;
	}
	
	.donor-thank-you {
		display: grid;
		column-gap: 40px;
		row-gap: 20px;
		margin: 20px;
		.thank-you-image {
			img {
				display: block;
				width: 250px;
				margin: auto;
			}
		}
		.tablet({
			grid-template-columns: 1fr auto;
		});
		.tablet-and-smaller({
			.thank-you-image {
				grid-row: 1;
			}
		});
	}
	
	.program-sponsorship-block {
		margin: 20px;
		h1 {
			.cormorant;
		}
		.the-sponsors {
			h3 {
				color: @tan-text;
			}
			h3:first-child {
				margin-top: 0;
			}
			p {
				margin: 0;
			}
			
			p + h3 {
				margin-top: 30px;
			}
			.tablet({
				column-count: 2;
			});
			.desktop({
				column-count: 3;
			});
		}
	}
	
	.foot-picture {
		img {
			display: block;
			margin: auto auto 30px auto;
			max-width: 100%;
		}
		.tablet({
			display: none;
		});
	}
}

.youtube-poster {
	.ratio(9/16);
	background-image: url('/img/YouTube_Placeholder.jpg');
	background-position: center center;
	background-size: cover;
}

.jumbo.header-layout.contact-us-alt-header-picture-background {
	background-position: center center;
}

.news-item-detail {
	padding-top: 40px;
	.pic {
		img {
			display: block;
			margin: 0 auto 40px auto;
			max-width: 100%;
		}
	}
}

.rochester-symphony-chia-hsuan-lin-picture {
	img {
		height: auto !important;
	}
}

.ready-to-load-img .contact-us-alt-header-picture-background, .ready-to-load-img .contact-us-alt-header-picture-background-with-ratio {
	.ready-to-load-img.about-page-header-picture-background();
}
.contact-us-alt-header-picture-background-ratio, .contact-us-alt-header-picture-background-with-ratio {
	.about-page-header-picture-background-ratio();
}

.edit-content-inline {
	img {
		max-width: 100%;
	}
}