
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.about-artistic-director-back-picture img {
	width: 1605px;
}

.ready-to-load-img .about-artistic-director-back-picture-background, .ready-to-load-img .about-artistic-director-back-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/about-artistic-director-back.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/about-artistic-director-back.webp");
	}
	@media (max-width: 802.5px) {

		background-image: url("@{cdn0}/img/about-artistic-director-back-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/about-artistic-director-back-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/about-artistic-director-back-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/about-artistic-director-back-2x.webp");
		}
		
		@media (max-width: 802.5px) {

			background-image: url("@{cdn0}/img/about-artistic-director-back.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/about-artistic-director-back.webp");
			}
		}
	}
}

.about-artistic-director-back-picture-background-ratio, .about-artistic-director-back-picture-background-with-ratio {
	.ratio(0.3118380062305296);
	background-position: center center;
	background-size: cover;
}

.about-history-background-picture img {
	width: 1920px;
}

.ready-to-load-img .about-history-background-picture-background, .ready-to-load-img .about-history-background-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/about-history-background.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/about-history-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/about-history-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/about-history-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/about-history-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/about-history-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/about-history-background.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/about-history-background.webp");
			}
		}
	}
}

.about-history-background-picture-background-ratio, .about-history-background-picture-background-with-ratio {
	.ratio(0.45286458333333335);
	background-position: center center;
	background-size: cover;
}

.about-jere-lantz-picture img {
	width: 1920px;
}

.ready-to-load-img .about-jere-lantz-picture-background, .ready-to-load-img .about-jere-lantz-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/about-jere-lantz.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/about-jere-lantz.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/about-jere-lantz-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/about-jere-lantz-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/about-jere-lantz-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/about-jere-lantz-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/about-jere-lantz.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/about-jere-lantz.webp");
			}
		}
	}
}

.about-jere-lantz-picture-background-ratio, .about-jere-lantz-picture-background-with-ratio {
	.ratio(0.5026041666666666);
	background-position: center center;
	background-size: cover;
}

.about-page-header-picture img {
	width: 1920px;
}

.ready-to-load-img .about-page-header-picture-background, .ready-to-load-img .about-page-header-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/about-page-header.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/about-page-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/about-page-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/about-page-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/about-page-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/about-page-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/about-page-header.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/about-page-header.webp");
			}
		}
	}
}

.about-page-header-picture-background-ratio, .about-page-header-picture-background-with-ratio {
	.ratio(0.25729166666666664);
	background-position: center center;
	background-size: cover;
}

.audition-form-side-picture img {
	width: 530px;
}

.ready-to-load-img .audition-form-side-picture-background, .ready-to-load-img .audition-form-side-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/audition-form-side.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/audition-form-side.webp");
	}
	@media (max-width: 265px) {

		background-image: url("@{cdn0}/img/audition-form-side-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/audition-form-side-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/audition-form-side-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/audition-form-side-2x.webp");
		}
		
		@media (max-width: 265px) {

			background-image: url("@{cdn0}/img/audition-form-side.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/audition-form-side.webp");
			}
		}
	}
}

.audition-form-side-picture-background-ratio, .audition-form-side-picture-background-with-ratio {
	.ratio(0.6084905660377359);
	background-position: center center;
	background-size: cover;
}

.auditioning-singer-picture img {
	width: 530px;
}

.ready-to-load-img .auditioning-singer-picture-background, .ready-to-load-img .auditioning-singer-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/auditioning-singer.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/auditioning-singer.webp");
	}
	@media (max-width: 265px) {

		background-image: url("@{cdn1}/img/auditioning-singer-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/auditioning-singer-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/auditioning-singer-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/auditioning-singer-2x.webp");
		}
		
		@media (max-width: 265px) {

			background-image: url("@{cdn1}/img/auditioning-singer.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/auditioning-singer.webp");
			}
		}
	}
}

.auditioning-singer-picture-background-ratio, .auditioning-singer-picture-background-with-ratio {
	.ratio(0.6084905660377359);
	background-position: center center;
	background-size: cover;
}

.beauty-of-anguish-detail-picture img {
	width: 407px;
}

.ready-to-load-img .beauty-of-anguish-detail-picture-background, .ready-to-load-img .beauty-of-anguish-detail-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/beauty-of-anguish-detail.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/beauty-of-anguish-detail.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn2}/img/beauty-of-anguish-detail-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/beauty-of-anguish-detail-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/beauty-of-anguish-detail-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/beauty-of-anguish-detail-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn2}/img/beauty-of-anguish-detail.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/beauty-of-anguish-detail.webp");
			}
		}
	}
}

.beauty-of-anguish-detail-picture-background-ratio, .beauty-of-anguish-detail-picture-background-with-ratio {
	.ratio(1.2051597051597052);
	background-position: center center;
	background-size: cover;
}

.black-chevron-left-picture img {
	width: 23.5px;
}

.ready-to-load-img .black-chevron-left-picture-background, .ready-to-load-img .black-chevron-left-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/black-chevron-left.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/black-chevron-left.webp");
	}
	@media (max-width: 11.75px) {

		background-image: url("@{cdn3}/img/black-chevron-left-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/black-chevron-left-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/black-chevron-left-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/black-chevron-left-2x.webp");
		}
		
		@media (max-width: 11.75px) {

			background-image: url("@{cdn3}/img/black-chevron-left.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/black-chevron-left.webp");
			}
		}
	}
}

.black-chevron-left-picture-background-ratio, .black-chevron-left-picture-background-with-ratio {
	.ratio(1.702127659574468);
	background-position: center center;
	background-size: cover;
}

.black-chevron-right-picture img {
	width: 23.5px;
}

.ready-to-load-img .black-chevron-right-picture-background, .ready-to-load-img .black-chevron-right-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/black-chevron-right.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/black-chevron-right.webp");
	}
	@media (max-width: 11.75px) {

		background-image: url("@{cdn0}/img/black-chevron-right-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/black-chevron-right-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/black-chevron-right-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/black-chevron-right-2x.webp");
		}
		
		@media (max-width: 11.75px) {

			background-image: url("@{cdn0}/img/black-chevron-right.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/black-chevron-right.webp");
			}
		}
	}
}

.black-chevron-right-picture-background-ratio, .black-chevron-right-picture-background-with-ratio {
	.ratio(1.702127659574468);
	background-position: center center;
	background-size: cover;
}

.blue-donate-background-picture img {
	width: 1920px;
}

.ready-to-load-img .blue-donate-background-picture-background, .ready-to-load-img .blue-donate-background-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/blue-donate-background.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/blue-donate-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/blue-donate-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/blue-donate-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/blue-donate-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/blue-donate-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/blue-donate-background.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/blue-donate-background.webp");
			}
		}
	}
}

.blue-donate-background-picture-background-ratio, .blue-donate-background-picture-background-with-ratio {
	.ratio(0.46588541666666666);
	background-position: center center;
	background-size: cover;
}

.bring-great-music-to-life-picture img {
	width: 1305px;
}

.ready-to-load-img .bring-great-music-to-life-picture-background, .ready-to-load-img .bring-great-music-to-life-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/bring-great-music-to-life.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/bring-great-music-to-life.webp");
	}
	@media (max-width: 652.5px) {

		background-image: url("@{cdn2}/img/bring-great-music-to-life-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/bring-great-music-to-life-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/bring-great-music-to-life-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/bring-great-music-to-life-2x.webp");
		}
		
		@media (max-width: 652.5px) {

			background-image: url("@{cdn2}/img/bring-great-music-to-life.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/bring-great-music-to-life.webp");
			}
		}
	}
}

.bring-great-music-to-life-picture-background-ratio, .bring-great-music-to-life-picture-background-with-ratio {
	.ratio(0.439463601532567);
	background-position: center center;
	background-size: cover;
}

.chorale-selector-picture img {
	width: 612px;
}

.ready-to-load-img .chorale-selector-picture-background, .ready-to-load-img .chorale-selector-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/chorale-selector.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/chorale-selector.webp");
	}
	@media (max-width: 306px) {

		background-image: url("@{cdn3}/img/chorale-selector-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/chorale-selector-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/chorale-selector-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/chorale-selector-2x.webp");
		}
		
		@media (max-width: 306px) {

			background-image: url("@{cdn3}/img/chorale-selector.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/chorale-selector.webp");
			}
		}
	}
}

.chorale-selector-picture-background-ratio, .chorale-selector-picture-background-with-ratio {
	.ratio(0.39215686274509803);
	background-position: center center;
	background-size: cover;
}

.concert-4th-graders-picture img {
	width: 115px;
}

.ready-to-load-img .concert-4th-graders-picture-background, .ready-to-load-img .concert-4th-graders-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/concert-4th-graders.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/concert-4th-graders.webp");
	}
	@media (max-width: 57.5px) {

		background-image: url("@{cdn0}/img/concert-4th-graders-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/concert-4th-graders-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/concert-4th-graders-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/concert-4th-graders-2x.webp");
		}
		
		@media (max-width: 57.5px) {

			background-image: url("@{cdn0}/img/concert-4th-graders.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/concert-4th-graders.webp");
			}
		}
	}
}

.concert-4th-graders-picture-background-ratio, .concert-4th-graders-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.contact-us-header-picture img {
	width: 1920px;
}

.ready-to-load-img .contact-us-header-picture-background, .ready-to-load-img .contact-us-header-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/contact-us-header.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/contact-us-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/contact-us-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/contact-us-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/contact-us-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/contact-us-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/contact-us-header.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/contact-us-header.webp");
			}
		}
	}
}

.contact-us-header-picture-background-ratio, .contact-us-header-picture-background-with-ratio {
	.ratio(0.25729166666666664);
	background-position: center center;
	background-size: cover;
}

.donate-header-picture img {
	width: 1920px;
}

.ready-to-load-img .donate-header-picture-background, .ready-to-load-img .donate-header-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/donate-header.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/donate-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/donate-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/donate-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/donate-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/donate-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/donate-header.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/donate-header.webp");
			}
		}
	}
}

.donate-header-picture-background-ratio, .donate-header-picture-background-with-ratio {
	.ratio(0.25729166666666664);
	background-position: center center;
	background-size: cover;
}

.donate-jere-lentz-profile-picture img {
	width: 636px;
}

.ready-to-load-img .donate-jere-lentz-profile-picture-background, .ready-to-load-img .donate-jere-lentz-profile-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/donate-jere-lentz-profile.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/donate-jere-lentz-profile.webp");
	}
	@media (max-width: 318px) {

		background-image: url("@{cdn3}/img/donate-jere-lentz-profile-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/donate-jere-lentz-profile-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/donate-jere-lentz-profile-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/donate-jere-lentz-profile-2x.webp");
		}
		
		@media (max-width: 318px) {

			background-image: url("@{cdn3}/img/donate-jere-lentz-profile.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/donate-jere-lentz-profile.webp");
			}
		}
	}
}

.donate-jere-lentz-profile-picture-background-ratio, .donate-jere-lentz-profile-picture-background-with-ratio {
	.ratio(1.4889937106918238);
	background-position: center center;
	background-size: cover;
}

.education-header-picture img {
	width: 1920px;
}

.ready-to-load-img .education-header-picture-background, .ready-to-load-img .education-header-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/education-header.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/education-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/education-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/education-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/education-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/education-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/education-header.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/education-header.webp");
			}
		}
	}
}

.education-header-picture-background-ratio, .education-header-picture-background-with-ratio {
	.ratio(0.25729166666666664);
	background-position: center center;
	background-size: cover;
}

.facebook-footer-picture img {
	width: 28px;
}

.ready-to-load-img .facebook-footer-picture-background, .ready-to-load-img .facebook-footer-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/facebook-footer.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/facebook-footer.webp");
	}
	@media (max-width: 14px) {

		background-image: url("@{cdn1}/img/facebook-footer-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/facebook-footer-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/facebook-footer-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/facebook-footer-2x.webp");
		}
		
		@media (max-width: 14px) {

			background-image: url("@{cdn1}/img/facebook-footer.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/facebook-footer.webp");
			}
		}
	}
}

.facebook-footer-picture-background-ratio, .facebook-footer-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.facebook-header-picture img {
	width: 15.5px;
}

.ready-to-load-img .facebook-header-picture-background, .ready-to-load-img .facebook-header-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/facebook-header.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/facebook-header.webp");
	}
	@media (max-width: 7.75px) {

		background-image: url("@{cdn2}/img/facebook-header-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/facebook-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/facebook-header-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/facebook-header-2x.webp");
		}
		
		@media (max-width: 7.75px) {

			background-image: url("@{cdn2}/img/facebook-header.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/facebook-header.webp");
			}
		}
	}
}

.facebook-header-picture-background-ratio, .facebook-header-picture-background-with-ratio {
	.ratio(1.032258064516129);
	background-position: center center;
	background-size: cover;
}

.free-family-previews-picture img {
	width: 115px;
}

.ready-to-load-img .free-family-previews-picture-background, .ready-to-load-img .free-family-previews-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/free-family-previews.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/free-family-previews.webp");
	}
	@media (max-width: 57.5px) {

		background-image: url("@{cdn3}/img/free-family-previews-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/free-family-previews-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/free-family-previews-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/free-family-previews-2x.webp");
		}
		
		@media (max-width: 57.5px) {

			background-image: url("@{cdn3}/img/free-family-previews.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/free-family-previews.webp");
			}
		}
	}
}

.free-family-previews-picture-background-ratio, .free-family-previews-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.girl-with-violin-picture img {
	width: 642px;
}

.ready-to-load-img .girl-with-violin-picture-background, .ready-to-load-img .girl-with-violin-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/girl-with-violin.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/girl-with-violin.webp");
	}
	@media (max-width: 321px) {

		background-image: url("@{cdn0}/img/girl-with-violin-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/girl-with-violin-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/girl-with-violin-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/girl-with-violin-2x.webp");
		}
		
		@media (max-width: 321px) {

			background-image: url("@{cdn0}/img/girl-with-violin.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/girl-with-violin.webp");
			}
		}
	}
}

.girl-with-violin-picture-background-ratio, .girl-with-violin-picture-background-with-ratio {
	.ratio(0.5607476635514018);
	background-position: center center;
	background-size: cover;
}

.go-arrow-picture img {
	width: 38.5px;
}

.ready-to-load-img .go-arrow-picture-background, .ready-to-load-img .go-arrow-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/go-arrow.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/go-arrow.webp");
	}
	@media (max-width: 19.25px) {

		background-image: url("@{cdn1}/img/go-arrow-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/go-arrow-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/go-arrow-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/go-arrow-2x.webp");
		}
		
		@media (max-width: 19.25px) {

			background-image: url("@{cdn1}/img/go-arrow.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/go-arrow.webp");
			}
		}
	}
}

.go-arrow-picture-background-ratio, .go-arrow-picture-background-with-ratio {
	.ratio(0.987012987012987);
	background-position: center center;
	background-size: cover;
}

.header-staff-left-picture img {
	width: 23.5px;
}

.ready-to-load-img .header-staff-left-picture-background, .ready-to-load-img .header-staff-left-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/header-staff-left.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/header-staff-left.webp");
	}
	@media (max-width: 11.75px) {

		background-image: url("@{cdn2}/img/header-staff-left-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/header-staff-left-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/header-staff-left-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/header-staff-left-2x.webp");
		}
		
		@media (max-width: 11.75px) {

			background-image: url("@{cdn2}/img/header-staff-left.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/header-staff-left.webp");
			}
		}
	}
}

.header-staff-left-picture-background-ratio, .header-staff-left-picture-background-with-ratio {
	.ratio(6.361702127659575);
	background-position: center center;
	background-size: cover;
}

.header-staff-right-picture img {
	width: 71.5px;
}

.ready-to-load-img .header-staff-right-picture-background, .ready-to-load-img .header-staff-right-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/header-staff-right.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/header-staff-right.webp");
	}
	@media (max-width: 35.75px) {

		background-image: url("@{cdn3}/img/header-staff-right-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/header-staff-right-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/header-staff-right-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/header-staff-right-2x.webp");
		}
		
		@media (max-width: 35.75px) {

			background-image: url("@{cdn3}/img/header-staff-right.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/header-staff-right.webp");
			}
		}
	}
}

.header-staff-right-picture-background-ratio, .header-staff-right-picture-background-with-ratio {
	.ratio(2.090909090909091);
	background-position: center center;
	background-size: cover;
}

.honk-squeak-picture img {
	width: 115px;
}

.ready-to-load-img .honk-squeak-picture-background, .ready-to-load-img .honk-squeak-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/honk-squeak.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/honk-squeak.webp");
	}
	@media (max-width: 57.5px) {

		background-image: url("@{cdn0}/img/honk-squeak-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/honk-squeak-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/honk-squeak-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/honk-squeak-2x.webp");
		}
		
		@media (max-width: 57.5px) {

			background-image: url("@{cdn0}/img/honk-squeak.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/honk-squeak.webp");
			}
		}
	}
}

.honk-squeak-picture-background-ratio, .honk-squeak-picture-background-with-ratio {
	.ratio(1.0043478260869565);
	background-position: center center;
	background-size: cover;
}

.instagram-footer-picture img {
	width: 28px;
}

.ready-to-load-img .instagram-footer-picture-background, .ready-to-load-img .instagram-footer-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/instagram-footer.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/instagram-footer.webp");
	}
	@media (max-width: 14px) {

		background-image: url("@{cdn1}/img/instagram-footer-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/instagram-footer-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/instagram-footer-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/instagram-footer-2x.webp");
		}
		
		@media (max-width: 14px) {

			background-image: url("@{cdn1}/img/instagram-footer.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/instagram-footer.webp");
			}
		}
	}
}

.instagram-footer-picture-background-ratio, .instagram-footer-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.instagram-header-picture img {
	width: 15.5px;
}

.ready-to-load-img .instagram-header-picture-background, .ready-to-load-img .instagram-header-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/instagram-header.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/instagram-header.webp");
	}
	@media (max-width: 7.75px) {

		background-image: url("@{cdn2}/img/instagram-header-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/instagram-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/instagram-header-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/instagram-header-2x.webp");
		}
		
		@media (max-width: 7.75px) {

			background-image: url("@{cdn2}/img/instagram-header.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/instagram-header.webp");
			}
		}
	}
}

.instagram-header-picture-background-ratio, .instagram-header-picture-background-with-ratio {
	.ratio(1.032258064516129);
	background-position: center center;
	background-size: cover;
}

.jere-mobile-picture img {
	width: 828px;
}

.ready-to-load-img .jere-mobile-picture-background, .ready-to-load-img .jere-mobile-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/jere-mobile.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/jere-mobile.webp");
	}
	@media (max-width: 414px) {

		background-image: url("@{cdn3}/img/jere-mobile-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/jere-mobile-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/jere-mobile-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/jere-mobile-2x.webp");
		}
		
		@media (max-width: 414px) {

			background-image: url("@{cdn3}/img/jere-mobile.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/jere-mobile.webp");
			}
		}
	}
}

.jere-mobile-picture-background-ratio, .jere-mobile-picture-background-with-ratio {
	.ratio(0.8176328502415459);
	background-position: center center;
	background-size: cover;
}

.jere-video-preview-picture img {
	width: 640px;
}

.ready-to-load-img .jere-video-preview-picture-background, .ready-to-load-img .jere-video-preview-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/jere-video-preview.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/jere-video-preview.webp");
	}
	@media (max-width: 320px) {

		background-image: url("@{cdn0}/img/jere-video-preview-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/jere-video-preview-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/jere-video-preview-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/jere-video-preview-2x.webp");
		}
		
		@media (max-width: 320px) {

			background-image: url("@{cdn0}/img/jere-video-preview.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/jere-video-preview.webp");
			}
		}
	}
}

.jere-video-preview-picture-background-ratio, .jere-video-preview-picture-background-with-ratio {
	.ratio(0.5625);
	background-position: center center;
	background-size: cover;
}

.land-legacy-sponsor-picture img {
	width: 98.5px;
}

.ready-to-load-img .land-legacy-sponsor-picture-background, .ready-to-load-img .land-legacy-sponsor-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/land-legacy-sponsor.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/land-legacy-sponsor.webp");
	}
	@media (max-width: 49.25px) {

		background-image: url("@{cdn1}/img/land-legacy-sponsor-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/land-legacy-sponsor-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/land-legacy-sponsor-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/land-legacy-sponsor-2x.webp");
		}
		
		@media (max-width: 49.25px) {

			background-image: url("@{cdn1}/img/land-legacy-sponsor.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/land-legacy-sponsor.webp");
			}
		}
	}
}

.land-legacy-sponsor-picture-background-ratio, .land-legacy-sponsor-picture-background-with-ratio {
	.ratio(1.883248730964467);
	background-position: center center;
	background-size: cover;
}

.landing-page-header-picture img {
	width: 1920px;
}

.ready-to-load-img .landing-page-header-picture-background, .ready-to-load-img .landing-page-header-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/landing-page-header.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/landing-page-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/landing-page-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/landing-page-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/landing-page-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/landing-page-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/landing-page-header.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/landing-page-header.webp");
			}
		}
	}
}

.landing-page-header-picture-background-ratio, .landing-page-header-picture-background-with-ratio {
	.ratio(0.5614583333333333);
	background-position: center center;
	background-size: cover;
}

.maestro-insight-picture img {
	width: 1920px;
}

.ready-to-load-img .maestro-insight-picture-background, .ready-to-load-img .maestro-insight-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/maestro-insight.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/maestro-insight.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/maestro-insight-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/maestro-insight-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/maestro-insight-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/maestro-insight-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/maestro-insight.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/maestro-insight.webp");
			}
		}
	}
}

.maestro-insight-picture-background-ratio, .maestro-insight-picture-background-with-ratio {
	.ratio(0.24947916666666667);
	background-position: center center;
	background-size: cover;
}

.mayo-clinic-sponsor-picture img {
	width: 113px;
}

.ready-to-load-img .mayo-clinic-sponsor-picture-background, .ready-to-load-img .mayo-clinic-sponsor-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/mayo-clinic-sponsor.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/mayo-clinic-sponsor.webp");
	}
	@media (max-width: 56.5px) {

		background-image: url("@{cdn0}/img/mayo-clinic-sponsor-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/mayo-clinic-sponsor-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/mayo-clinic-sponsor-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/mayo-clinic-sponsor-2x.webp");
		}
		
		@media (max-width: 56.5px) {

			background-image: url("@{cdn0}/img/mayo-clinic-sponsor.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/mayo-clinic-sponsor.webp");
			}
		}
	}
}

.mayo-clinic-sponsor-picture-background-ratio, .mayo-clinic-sponsor-picture-background-with-ratio {
	.ratio(1.092920353982301);
	background-position: center center;
	background-size: cover;
}

.mcgough-sponsor-picture img {
	width: 198px;
}

.ready-to-load-img .mcgough-sponsor-picture-background, .ready-to-load-img .mcgough-sponsor-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/mcgough-sponsor.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/mcgough-sponsor.webp");
	}
	@media (max-width: 99px) {

		background-image: url("@{cdn1}/img/mcgough-sponsor-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/mcgough-sponsor-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/mcgough-sponsor-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/mcgough-sponsor-2x.webp");
		}
		
		@media (max-width: 99px) {

			background-image: url("@{cdn1}/img/mcgough-sponsor.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/mcgough-sponsor.webp");
			}
		}
	}
}

.mcgough-sponsor-picture-background-ratio, .mcgough-sponsor-picture-background-with-ratio {
	.ratio(0.76010101010101);
	background-position: center center;
	background-size: cover;
}

.minus-picture img {
	width: 21.5px;
}

.ready-to-load-img .minus-picture-background, .ready-to-load-img .minus-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/minus.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/minus.webp");
	}
	@media (max-width: 10.75px) {

		background-image: url("@{cdn2}/img/minus-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/minus-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/minus-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/minus-2x.webp");
		}
		
		@media (max-width: 10.75px) {

			background-image: url("@{cdn2}/img/minus.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/minus.webp");
			}
		}
	}
}

.minus-picture-background-ratio, .minus-picture-background-with-ratio {
	.ratio(0.16279069767441862);
	background-position: center center;
	background-size: cover;
}

.more-ways-to-give-picture img {
	width: 1920px;
}

.ready-to-load-img .more-ways-to-give-picture-background, .ready-to-load-img .more-ways-to-give-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/more-ways-to-give.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/more-ways-to-give.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/more-ways-to-give-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/more-ways-to-give-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/more-ways-to-give-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/more-ways-to-give-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/more-ways-to-give.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/more-ways-to-give.webp");
			}
		}
	}
}

.more-ways-to-give-picture-background-ratio, .more-ways-to-give-picture-background-with-ratio {
	.ratio(0.19661458333333334);
	background-position: center center;
	background-size: cover;
}

.music-note-header-overlay-picture img {
	width: 1398.5px;
}

.ready-to-load-img .music-note-header-overlay-picture-background, .ready-to-load-img .music-note-header-overlay-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/music-note-header-overlay.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/music-note-header-overlay.webp");
	}
	@media (max-width: 699.25px) {

		background-image: url("@{cdn0}/img/music-note-header-overlay-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/music-note-header-overlay-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/music-note-header-overlay-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/music-note-header-overlay-2x.webp");
		}
		
		@media (max-width: 699.25px) {

			background-image: url("@{cdn0}/img/music-note-header-overlay.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/music-note-header-overlay.webp");
			}
		}
	}
}

.music-note-header-overlay-picture-background-ratio, .music-note-header-overlay-picture-background-with-ratio {
	.ratio(0.34215230604218805);
	background-position: center center;
	background-size: cover;
}

.orchestra-and-choral-header-picture img {
	width: 1920px;
}

.ready-to-load-img .orchestra-and-choral-header-picture-background, .ready-to-load-img .orchestra-and-choral-header-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/orchestra-and-choral-header.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/orchestra-and-choral-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/orchestra-and-choral-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/orchestra-and-choral-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/orchestra-and-choral-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/orchestra-and-choral-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/orchestra-and-choral-header.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/orchestra-and-choral-header.webp");
			}
		}
	}
}

.orchestra-and-choral-header-picture-background-ratio, .orchestra-and-choral-header-picture-background-with-ratio {
	.ratio(0.25729166666666664);
	background-position: center center;
	background-size: cover;
}

.orchestra-selector-picture img {
	width: 617px;
}

.ready-to-load-img .orchestra-selector-picture-background, .ready-to-load-img .orchestra-selector-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/orchestra-selector.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/orchestra-selector.webp");
	}
	@media (max-width: 308.5px) {

		background-image: url("@{cdn2}/img/orchestra-selector-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/orchestra-selector-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/orchestra-selector-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/orchestra-selector-2x.webp");
		}
		
		@media (max-width: 308.5px) {

			background-image: url("@{cdn2}/img/orchestra-selector.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/orchestra-selector.webp");
			}
		}
	}
}

.orchestra-selector-picture-background-ratio, .orchestra-selector-picture-background-with-ratio {
	.ratio(0.3889789303079417);
	background-position: center center;
	background-size: cover;
}

.original-orchestra-picture img {
	width: 963.5px;
}

.ready-to-load-img .original-orchestra-picture-background, .ready-to-load-img .original-orchestra-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/original-orchestra.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/original-orchestra.webp");
	}
	@media (max-width: 481.75px) {

		background-image: url("@{cdn3}/img/original-orchestra-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/original-orchestra-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/original-orchestra-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/original-orchestra-2x.webp");
		}
		
		@media (max-width: 481.75px) {

			background-image: url("@{cdn3}/img/original-orchestra.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/original-orchestra.webp");
			}
		}
	}
}

.original-orchestra-picture-background-ratio, .original-orchestra-picture-background-with-ratio {
	.ratio(0.4478463933575506);
	background-position: center center;
	background-size: cover;
}

.pdf-icon-picture img {
	width: 22.5px;
}

.ready-to-load-img .pdf-icon-picture-background, .ready-to-load-img .pdf-icon-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/pdf-icon.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/pdf-icon.webp");
	}
	@media (max-width: 11.25px) {

		background-image: url("@{cdn0}/img/pdf-icon-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/pdf-icon-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/pdf-icon-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/pdf-icon-2x.webp");
		}
		
		@media (max-width: 11.25px) {

			background-image: url("@{cdn0}/img/pdf-icon.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/pdf-icon.webp");
			}
		}
	}
}

.pdf-icon-picture-background-ratio, .pdf-icon-picture-background-with-ratio {
	.ratio(1.3111111111111111);
	background-position: center center;
	background-size: cover;
}

.play-button-picture img {
	width: 78px;
}

.ready-to-load-img .play-button-picture-background, .ready-to-load-img .play-button-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/play-button.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/play-button.webp");
	}
	@media (max-width: 39px) {

		background-image: url("@{cdn1}/img/play-button-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/play-button-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/play-button-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/play-button-2x.webp");
		}
		
		@media (max-width: 39px) {

			background-image: url("@{cdn1}/img/play-button.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/play-button.webp");
			}
		}
	}
}

.play-button-picture-background-ratio, .play-button-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.playing-with-the-pros-picture img {
	width: 115px;
}

.ready-to-load-img .playing-with-the-pros-picture-background, .ready-to-load-img .playing-with-the-pros-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/playing-with-the-pros.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/playing-with-the-pros.webp");
	}
	@media (max-width: 57.5px) {

		background-image: url("@{cdn2}/img/playing-with-the-pros-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/playing-with-the-pros-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/playing-with-the-pros-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/playing-with-the-pros-2x.webp");
		}
		
		@media (max-width: 57.5px) {

			background-image: url("@{cdn2}/img/playing-with-the-pros.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/playing-with-the-pros.webp");
			}
		}
	}
}

.playing-with-the-pros-picture-background-ratio, .playing-with-the-pros-picture-background-with-ratio {
	.ratio(1.0043478260869565);
	background-position: center center;
	background-size: cover;
}

.plus-picture img {
	width: 21.5px;
}

.ready-to-load-img .plus-picture-background, .ready-to-load-img .plus-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/plus.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/plus.webp");
	}
	@media (max-width: 10.75px) {

		background-image: url("@{cdn3}/img/plus-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/plus-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/plus-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/plus-2x.webp");
		}
		
		@media (max-width: 10.75px) {

			background-image: url("@{cdn3}/img/plus.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/plus.webp");
			}
		}
	}
}

.plus-picture-background-ratio, .plus-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.premier-bank-sponsor-picture img {
	width: 345px;
}

.ready-to-load-img .premier-bank-sponsor-picture-background, .ready-to-load-img .premier-bank-sponsor-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/premier-bank-sponsor.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/premier-bank-sponsor.webp");
	}
	@media (max-width: 172.5px) {

		background-image: url("@{cdn0}/img/premier-bank-sponsor-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/premier-bank-sponsor-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/premier-bank-sponsor-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/premier-bank-sponsor-2x.webp");
		}
		
		@media (max-width: 172.5px) {

			background-image: url("@{cdn0}/img/premier-bank-sponsor.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/premier-bank-sponsor.webp");
			}
		}
	}
}

.premier-bank-sponsor-picture-background-ratio, .premier-bank-sponsor-picture-background-with-ratio {
	.ratio(0.16376811594202897);
	background-position: center center;
	background-size: cover;
}

.right-chevron-picture img {
	width: 23.5px;
}

.ready-to-load-img .right-chevron-picture-background, .ready-to-load-img .right-chevron-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/right-chevron.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/right-chevron.webp");
	}
	@media (max-width: 11.75px) {

		background-image: url("@{cdn1}/img/right-chevron-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/right-chevron-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/right-chevron-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/right-chevron-2x.webp");
		}
		
		@media (max-width: 11.75px) {

			background-image: url("@{cdn1}/img/right-chevron.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/right-chevron.webp");
			}
		}
	}
}

.right-chevron-picture-background-ratio, .right-chevron-picture-background-with-ratio {
	.ratio(1.6170212765957446);
	background-position: center center;
	background-size: cover;
}

.season-subscriptions-background-picture img {
	width: 1920px;
}

.ready-to-load-img .season-subscriptions-background-picture-background, .ready-to-load-img .season-subscriptions-background-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/season-subscriptions-background.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/season-subscriptions-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/season-subscriptions-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/season-subscriptions-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/season-subscriptions-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/season-subscriptions-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/season-subscriptions-background.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/season-subscriptions-background.webp");
			}
		}
	}
}

.season-subscriptions-background-picture-background-ratio, .season-subscriptions-background-picture-background-with-ratio {
	.ratio(0.34270833333333334);
	background-position: center center;
	background-size: cover;
}

.season-subscriptions-picture img {
	width: 960px;
}

.ready-to-load-img .season-subscriptions-picture-background, .ready-to-load-img .season-subscriptions-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/season-subscriptions.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/season-subscriptions.webp");
	}
	@media (max-width: 480px) {

		background-image: url("@{cdn3}/img/season-subscriptions-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/season-subscriptions-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/season-subscriptions-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/season-subscriptions-2x.webp");
		}
		
		@media (max-width: 480px) {

			background-image: url("@{cdn3}/img/season-subscriptions.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/season-subscriptions.webp");
			}
		}
	}
}

.season-subscriptions-picture-background-ratio, .season-subscriptions-picture-background-with-ratio {
	.ratio(0.5338541666666666);
	background-position: center center;
	background-size: cover;
}

.seating-chart-background-picture img {
	width: 1920px;
}

.ready-to-load-img .seating-chart-background-picture-background, .ready-to-load-img .seating-chart-background-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/seating-chart-background.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/seating-chart-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/seating-chart-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/seating-chart-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/seating-chart-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/seating-chart-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/seating-chart-background.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/seating-chart-background.webp");
			}
		}
	}
}

.seating-chart-background-picture-background-ratio, .seating-chart-background-picture-background-with-ratio {
	.ratio(0.25729166666666664);
	background-position: center center;
	background-size: cover;
}

.seating-chart-picture img {
	width: 1229px;
}

.ready-to-load-img .seating-chart-picture-background, .ready-to-load-img .seating-chart-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/seating-chart.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/seating-chart.webp");
	}
	@media (max-width: 614.5px) {

		background-image: url("@{cdn1}/img/seating-chart-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/seating-chart-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/seating-chart-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/seating-chart-2x.webp");
		}
		
		@media (max-width: 614.5px) {

			background-image: url("@{cdn1}/img/seating-chart.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/seating-chart.webp");
			}
		}
	}
}

.seating-chart-picture-background-ratio, .seating-chart-picture-background-with-ratio {
	.ratio(1.5699755899104963);
	background-position: center center;
	background-size: cover;
}

.social-footer-icons-picture img {
	width: 161px;
}

.ready-to-load-img .social-footer-icons-picture-background, .ready-to-load-img .social-footer-icons-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/social-footer-icons.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/social-footer-icons.webp");
	}
	@media (max-width: 80.5px) {

		background-image: url("@{cdn2}/img/social-footer-icons-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/social-footer-icons-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/social-footer-icons-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/social-footer-icons-2x.webp");
		}
		
		@media (max-width: 80.5px) {

			background-image: url("@{cdn2}/img/social-footer-icons.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/social-footer-icons.webp");
			}
		}
	}
}

.social-footer-icons-picture-background-ratio, .social-footer-icons-picture-background-with-ratio {
	.ratio(0.17391304347826086);
	background-position: center center;
	background-size: cover;
}

.sounds-of-the-season-detail-picture img {
	width: 407px;
}

.ready-to-load-img .sounds-of-the-season-detail-picture-background, .ready-to-load-img .sounds-of-the-season-detail-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/sounds-of-the-season-detail.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/sounds-of-the-season-detail.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn3}/img/sounds-of-the-season-detail-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sounds-of-the-season-detail-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/sounds-of-the-season-detail-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sounds-of-the-season-detail-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn3}/img/sounds-of-the-season-detail.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/sounds-of-the-season-detail.webp");
			}
		}
	}
}

.sounds-of-the-season-detail-picture-background-ratio, .sounds-of-the-season-detail-picture-background-with-ratio {
	.ratio(0.9004914004914005);
	background-position: center center;
	background-size: cover;
}

.sponsor-muscian-background-picture img {
	width: 1578px;
}

.ready-to-load-img .sponsor-muscian-background-picture-background, .ready-to-load-img .sponsor-muscian-background-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/sponsor-muscian-background.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/sponsor-muscian-background.webp");
	}
	@media (max-width: 789px) {

		background-image: url("@{cdn0}/img/sponsor-muscian-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/sponsor-muscian-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/sponsor-muscian-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/sponsor-muscian-background-2x.webp");
		}
		
		@media (max-width: 789px) {

			background-image: url("@{cdn0}/img/sponsor-muscian-background.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/sponsor-muscian-background.webp");
			}
		}
	}
}

.sponsor-muscian-background-picture-background-ratio, .sponsor-muscian-background-picture-background-with-ratio {
	.ratio(0.6311787072243346);
	background-position: center center;
	background-size: cover;
}

.state-art-board-sponsor-picture img {
	width: 117px;
}

.ready-to-load-img .state-art-board-sponsor-picture-background, .ready-to-load-img .state-art-board-sponsor-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/state-art-board-sponsor.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/state-art-board-sponsor.webp");
	}
	@media (max-width: 58.5px) {

		background-image: url("@{cdn1}/img/state-art-board-sponsor-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/state-art-board-sponsor-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/state-art-board-sponsor-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/state-art-board-sponsor-2x.webp");
		}
		
		@media (max-width: 58.5px) {

			background-image: url("@{cdn1}/img/state-art-board-sponsor.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/state-art-board-sponsor.webp");
			}
		}
	}
}

.state-art-board-sponsor-picture-background-ratio, .state-art-board-sponsor-picture-background-with-ratio {
	.ratio(1.5854700854700854);
	background-position: center center;
	background-size: cover;
}

.strength-of-us-detail-picture img {
	width: 407px;
}

.ready-to-load-img .strength-of-us-detail-picture-background, .ready-to-load-img .strength-of-us-detail-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/strength-of-us-detail.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/strength-of-us-detail.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn2}/img/strength-of-us-detail-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/strength-of-us-detail-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/strength-of-us-detail-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/strength-of-us-detail-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn2}/img/strength-of-us-detail.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/strength-of-us-detail.webp");
			}
		}
	}
}

.strength-of-us-detail-picture-background-ratio, .strength-of-us-detail-picture-background-with-ratio {
	.ratio(1.203931203931204);
	background-position: center center;
	background-size: cover;
}

.sub-header-message-background-picture img {
	width: 1920px;
}

.ready-to-load-img .sub-header-message-background-picture-background, .ready-to-load-img .sub-header-message-background-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/sub-header-message-background.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/sub-header-message-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/sub-header-message-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sub-header-message-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/sub-header-message-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/sub-header-message-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/sub-header-message-background.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/sub-header-message-background.webp");
			}
		}
	}
}

.sub-header-message-background-picture-background-ratio, .sub-header-message-background-picture-background-with-ratio {
	.ratio(0.07604166666666666);
	background-position: center center;
	background-size: cover;
}

.symphony-footer-logo-picture img {
	width: 377.5px;
}

.ready-to-load-img .symphony-footer-logo-picture-background, .ready-to-load-img .symphony-footer-logo-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/symphony-footer-logo.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/symphony-footer-logo.webp");
	}
	@media (max-width: 188.75px) {

		background-image: url("@{cdn0}/img/symphony-footer-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/symphony-footer-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/symphony-footer-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/symphony-footer-logo-2x.webp");
		}
		
		@media (max-width: 188.75px) {

			background-image: url("@{cdn0}/img/symphony-footer-logo.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/symphony-footer-logo.webp");
			}
		}
	}
}

.symphony-footer-logo-picture-background-ratio, .symphony-footer-logo-picture-background-with-ratio {
	.ratio(0.29668874172185433);
	background-position: center center;
	background-size: cover;
}

.symphony-header-logo-picture img {
	width: 331px;
}

.ready-to-load-img .symphony-header-logo-picture-background, .ready-to-load-img .symphony-header-logo-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/symphony-header-logo.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/symphony-header-logo.webp");
	}
	@media (max-width: 165.5px) {

		background-image: url("@{cdn1}/img/symphony-header-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/symphony-header-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/symphony-header-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/symphony-header-logo-2x.webp");
		}
		
		@media (max-width: 165.5px) {

			background-image: url("@{cdn1}/img/symphony-header-logo.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/symphony-header-logo.webp");
			}
		}
	}
}

.symphony-header-logo-picture-background-ratio, .symphony-header-logo-picture-background-with-ratio {
	.ratio(0.297583081570997);
	background-position: center center;
	background-size: cover;
}

.tan-more-arrow-picture img {
	width: 18px;
}

.ready-to-load-img .tan-more-arrow-picture-background, .ready-to-load-img .tan-more-arrow-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/tan-more-arrow.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/tan-more-arrow.webp");
	}
	@media (max-width: 9px) {

		background-image: url("@{cdn2}/img/tan-more-arrow-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/tan-more-arrow-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/tan-more-arrow-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/tan-more-arrow-2x.webp");
		}
		
		@media (max-width: 9px) {

			background-image: url("@{cdn2}/img/tan-more-arrow.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/tan-more-arrow.webp");
			}
		}
	}
}

.tan-more-arrow-picture-background-ratio, .tan-more-arrow-picture-background-with-ratio {
	.ratio(1.6388888888888888);
	background-position: center center;
	background-size: cover;
}

.tickets-and-events-header-picture img {
	width: 1920px;
}

.ready-to-load-img .tickets-and-events-header-picture-background, .ready-to-load-img .tickets-and-events-header-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/tickets-and-events-header.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/tickets-and-events-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/tickets-and-events-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/tickets-and-events-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/tickets-and-events-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/tickets-and-events-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/tickets-and-events-header.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/tickets-and-events-header.webp");
			}
		}
	}
}

.tickets-and-events-header-picture-background-ratio, .tickets-and-events-header-picture-background-with-ratio {
	.ratio(0.25729166666666664);
	background-position: center center;
	background-size: cover;
}

.tickets-and-events-musician-picture img {
	width: 407px;
}

.ready-to-load-img .tickets-and-events-musician-picture-background, .ready-to-load-img .tickets-and-events-musician-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/tickets-and-events-musician.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/tickets-and-events-musician.webp");
	}
	@media (max-width: 203.5px) {

		background-image: url("@{cdn0}/img/tickets-and-events-musician-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/tickets-and-events-musician-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/tickets-and-events-musician-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/tickets-and-events-musician-2x.webp");
		}
		
		@media (max-width: 203.5px) {

			background-image: url("@{cdn0}/img/tickets-and-events-musician.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/tickets-and-events-musician.webp");
			}
		}
	}
}

.tickets-and-events-musician-picture-background-ratio, .tickets-and-events-musician-picture-background-with-ratio {
	.ratio(0.9004914004914005);
	background-position: center center;
	background-size: cover;
}

.twitter-footer-picture img {
	width: 32.5px;
}

.ready-to-load-img .twitter-footer-picture-background, .ready-to-load-img .twitter-footer-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/twitter-footer.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/twitter-footer.webp");
	}
	@media (max-width: 16.25px) {

		background-image: url("@{cdn1}/img/twitter-footer-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/twitter-footer-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/twitter-footer-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/twitter-footer-2x.webp");
		}
		
		@media (max-width: 16.25px) {

			background-image: url("@{cdn1}/img/twitter-footer.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/twitter-footer.webp");
			}
		}
	}
}

.twitter-footer-picture-background-ratio, .twitter-footer-picture-background-with-ratio {
	.ratio(0.8615384615384616);
	background-position: center center;
	background-size: cover;
}

.twitter-header-picture img {
	width: 17.5px;
}

.ready-to-load-img .twitter-header-picture-background, .ready-to-load-img .twitter-header-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/twitter-header.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/twitter-header.webp");
	}
	@media (max-width: 8.75px) {

		background-image: url("@{cdn2}/img/twitter-header-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/twitter-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/twitter-header-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/twitter-header-2x.webp");
		}
		
		@media (max-width: 8.75px) {

			background-image: url("@{cdn2}/img/twitter-header.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/twitter-header.webp");
			}
		}
	}
}

.twitter-header-picture-background-ratio, .twitter-header-picture-background-with-ratio {
	.ratio(0.9142857142857143);
	background-position: center center;
	background-size: cover;
}

.vert-line-picture img {
	width: 7px;
}

.ready-to-load-img .vert-line-picture-background, .ready-to-load-img .vert-line-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/vert-line.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/vert-line.webp");
	}
	@media (max-width: 3.5px) {

		background-image: url("@{cdn3}/img/vert-line-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/vert-line-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/vert-line-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/vert-line-2x.webp");
		}
		
		@media (max-width: 3.5px) {

			background-image: url("@{cdn3}/img/vert-line.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/vert-line.webp");
			}
		}
	}
}

.vert-line-picture-background-ratio, .vert-line-picture-background-with-ratio {
	.ratio(5.428571428571429);
	background-position: center center;
	background-size: cover;
}

.youtube-footer-picture img {
	width: 35px;
}

.ready-to-load-img .youtube-footer-picture-background, .ready-to-load-img .youtube-footer-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/youtube-footer.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/youtube-footer.webp");
	}
	@media (max-width: 17.5px) {

		background-image: url("@{cdn0}/img/youtube-footer-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/youtube-footer-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/youtube-footer-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/youtube-footer-2x.webp");
		}
		
		@media (max-width: 17.5px) {

			background-image: url("@{cdn0}/img/youtube-footer.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/youtube-footer.webp");
			}
		}
	}
}

.youtube-footer-picture-background-ratio, .youtube-footer-picture-background-with-ratio {
	.ratio(0.8);
	background-position: center center;
	background-size: cover;
}

.youtube-header-picture img {
	width: 19px;
}

.ready-to-load-img .youtube-header-picture-background, .ready-to-load-img .youtube-header-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/youtube-header.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/youtube-header.webp");
	}
	@media (max-width: 9.5px) {

		background-image: url("@{cdn1}/img/youtube-header-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/youtube-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/youtube-header-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/youtube-header-2x.webp");
		}
		
		@media (max-width: 9.5px) {

			background-image: url("@{cdn1}/img/youtube-header.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/youtube-header.webp");
			}
		}
	}
}

.youtube-header-picture-background-ratio, .youtube-header-picture-background-with-ratio {
	.ratio(0.8421052631578947);
	background-position: center center;
	background-size: cover;
}
