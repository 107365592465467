.std-txt {
	h1 {
		.cormorant;
		.fs(@page-h1-size);
		text-transform: uppercase;
	}
	h2 {
		.fs(@page-h2-size);
	}
	h3 {
		.fs(@page-h3-size);
	}
	h4 {
		.fs(@page-h2-size);
		color: @tan-text;
		font-weight: 400;
	}
	
	p {
		.fs(@page-p-size);
	}
}
.two-level {
	.fs(@page-h2-size);
	font-weight: 300;
	line-height: 1.5em;
	strong {
		.cormorant;
		.fs(@page-h1-size);
		display: block;
		font-weight: 400;
		text-transform: uppercase;
	}
}


.black-show {
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: black;
	position: relative;
	overflow: hidden;
	
	.container {
		position: relative;
		z-index: 0;
	}
	h1 {
		color: @tan-text;
		strong {
			color: white;
		}
	}
	
	p {
		color: white;
	}
	
	.backer img {
		position: absolute;
		top: 50%;
		right: -399px;
		z-index: -1;
		transform: translateY(-50%);
	}
}

.hard-left-side {
	display: grid;
	
	grid-template-columns: minmax(300px, 33%) minmax(auto, 620px) auto;
	column-gap: 60px;
	background-color: @box-back;
	.img {
		background-size: cover;
		background-position: center;
	}
	
	.msg, .side {
	}
	.msg {
		padding-top: 55px;
		padding-bottom: 55px;
		
		h1 {
			margin-top: 0;
			.two-level;
		}
	}
	
}
.hard-right-side {
	display: grid;
	
	.desktop({
		grid-template-columns: 1fr (@container-width * .5) (@container-width * .5) 1fr;

	});
	background-color: @box-back;
	
	.img {
		background-size: cover;
		background-position: center;
		grid-column: span 2;
	}
	
	.msg {
		
		h1 {
			margin-top: 0;
			.two-level;
		}
	}
	
	.side {
		min-width: @page-side-padding;
	}
	
}
.planning {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: black;
	color: white;
	text-align: center;
	.container {
		max-width: 700px;
	}
	h1 {
		.fs(40);
		margin-top: 0;
		.cormorant;
		text-transform: uppercase;
	}
}
.balanced-side {
	.full-back;
	padding-top: 50px;
	padding-bottom: 50px;
	
	&.grey {
		background-color: @grey-box-back;
	}
	
	.desktop({
		.container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 50px;
		}
	});
	.msg.box-out {
		border: 2px solid @tan-text;
		padding: 20px;
		align-self: stretch;
	}
	.box-button {
		display: inline-block;
		text-transform: none;
		padding: 16px 20px;
		font-weight: 600;
		
		&.reversed {
			background-color: @tan-text;
			color: white;
		}
		
		&.stack-button {
			margin-bottom: 10px;
			.big-phone({
				min-width: 300px;
			});
			.big-phone-and-smaller({
				width: 100%;
				box-sizing: border-box;
			});
			text-align: center;
		}
	}
	
	.msg + .msg {
		img {
			max-width: 100%;
		}
	}
}

.hard-right-side-with-back {
	.hard-right-side;
	.full-back;
	padding-top: 50px;
	padding-bottom: 50px;
	.msg {
		padding-right: 60px;
		.box-button {
			display: inline-block;
			text-transform: none;
			padding: 16px 20px;
			font-weight: 600;
		}
		
	}
}

.two-col-section {
	.msg {
		h1 {
			.fs(40);
			margin: 0;
			.cormorant;
			text-transform: uppercase;
			margin-top: -10px;
		}
		h2 {
			.fs(20);
			margin-top: 0;
			margin-bottom: 40px;
			font-weight: 400;
		}
		
		p {
			strong {
				color: @tan-text;
				&.black {
					color: black;
				}
			}
			strong.accent {
				text-transform: uppercase;
				font-size: 85%;
			}
			
			&.tan {
				color: @tan-text;
			}
		}
		.big-phone-and-smaller({
			padding-right: @page-side-padding;
			h1 {
				.fs(30);
			}
		})
	}
}

.center-box {
	.std-txt;
	background-position: center;
	background-size: cover;
	padding-top: 50px;
	padding-bottom: 50px;

	.container {
		text-align: center;
	}
	
	.halfer {
		margin-left: auto;
		margin-right: auto;
	}
}

.halfer {
	max-width: @container-width / 2;
}

.hollow-button {
	.fs(14);
	font-weight: 600;
	text-decoration: none;
	border-width: 1;
	border-style: solid;
	padding: 10px 18px;
	line-height: 1em;
	text-align: center;
	margin-top: 10px;
	
	&.with-image {
		img {
			display: inline-block;
			vertical-align: middle;
			width: 15px;
			margin-left: 10px;
		}
	}
}

.hollow-button-link {
	a {
		.hollow-button;
	}
}

.box-button {
	border: 0;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 1em;
	padding: 22px 45px;
	background-color: white;
	color: @tan-text;
}

.pub-form {
	label {
		display: block;
		margin-top: 10px;
	}
	input[type=text] {
		display: inline-block;
		border: 0;
		background-color: white;
		padding: 7px;
	}
	
	button[type=submit] {
		display: inline-block;
		margin-top: 30px;
		background-color: black;
		border: 0;
		line-height: 1em;
		padding: 11px 22px;
		color: white;
		font-weight: 600;
	}
}

.performance-dates, .program-notes, .featured-musicians, .advertisements, .concert-sponsors {
	background-color: white;
	margin-bottom: 50px;
	
	.set-actions {
		a {
			text-decoration: underline;
			cursor: pointer;
		}
	}
	
	.performance-date-summary, .program-note-summary, .featured-musician-summary, .advertisement-summary, .concert-sponsor-summary {
		padding: 10px;
		/*
		background-color: @grey-box-back;
		margin-bottom: 10px;
		*/
		.actions {
			a, button {
				display: block;
				cursor: pointer;
				margin-bottom: 10px;
				font-size: 14pt;
				color: inherit;
				text-decoration: none;
				border: 0;
				background: none;
				padding: 0;
			}
		}
		img {
			max-width: 100%;
		}
	}
}
.ei-editable-sortable-tiles {
	.tiles {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

.program-note-summary {
	.details {
		.notes {
			height: 4em;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.featured-musician-summary, .advertisement-summary {
	.clear-inner;
	.musician-photo, .ad-photo {
		float: right;
		max-width: 150px !important;
	}
}


.program-note {
	margin-bottom: 20px;
	& > .header {
		background-color: @grey-box-back;
		padding: 8px 14px;
		cursor: pointer;
		strong {
			color: @tan-text;
		}
		.marker {
			float: right;
		}
		p {
			margin: 0;
			display: inline-block;
		}
	}
	
	.content-holder {
		max-height: 0;
		transition: max-height .3s;
		overflow: hidden;
	}
}

.program-summary {
	margin-bottom: 20px;
	.program-header {
		padding: 8px 14px;
		cursor: pointer;
		& > .marker {
			float: right;
			margin-top: 30px;
		}
	}
	
	.program-content-holder {
		max-height: 0;
		transition: max-height .3s;
		overflow: hidden;
	}
}
.maestro-insight {
	.full-back;
	padding-top: 50px;
	padding-bottom: 50px;
	.container {
		display: grid;
		grid-template-columns: 3fr 4fr;
		column-gap: 40px;
	}
	
	.pic {
		img {
			max-width: 100%;
		}
	}
	.msg {
		align-self: center;
		padding-right: 150px;
		h1 {
			.cormorant;
			margin-top: 0;
			margin-bottom: 0;
			text-transform: uppercase;
		}
		h2 {
			.cormorant;
			text-transform: uppercase;
		}
	}
	.tablet-and-smaller({
		.container {
			display: block;
		}
		
	});
}

.tan-button {
	display: inline-block;
	color: white;
	background-color: @tan-button-back;
	padding: 15px;
	min-width: 150px;
	text-decoration: none;
	font-weight: 600;
	img {
		float: right;
		padding-left: 30px;
		width: 12px;
		padding-top: 3px;
	}
	
	
	&.center-text {
		text-align: center;
	}
}
.hollow-button {
	display: inline-block;
	background-color: white;
	border: solid 1px @tan-button-back;
	padding: 15px;
	min-width: 150px;
	text-decoration: none;
	font-weight: 600;
	color: black;
}

.education-link {
	display: block;
	text-decoration: none;
	border: solid 1px @tan-text;
	padding: 12px;
	margin-bottom: 10px;
	color: black;
	strong {
		color: black !important;
	}
	
	&.highlighted {
		background-color: @tan-text;
		color: white;
		strong {
			color: white !important;
		}
	}
}

.education-tiles {
	display: grid;
	column-gap: 70px;
	row-gap: 70px;
	margin-bottom: 80px;
	
	.tablet({
		grid-template-columns: repeat(2, 1fr);
	});
	
	.education-tile {
		display: grid;
		column-gap: 20px;
		grid-template-columns: 1fr 4fr;
		.pic {
			img {
				max-width: 100%;
			}
		}	
		a {
			color: @tan-text;
		}
	}
}

.event-tile {
	text-decoration: none;
	color: black;
	.pic {
		img {
			max-width: 100%;
		}
	}
	
	h2 {
		.cormorant;
		text-transform: uppercase;
	}
	.performance-date-line {
		strong {
			color: @tan-text;
		}
	}
}

.event-summary {
	margin-top: 50px;
	margin-bottom: 50px;

	.tablet({
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 30px;
		
		.details {
			grid-row: 1;
			grid-column: 1;
		}
		.img {
			grid-column: 2;
			grid-row: 1;
		}
		.chorale-featured {
			margin-bottom: 10px;
		}
	});
	.img {
		img {
			max-width: 100%;
		}
	}
	.content {
		position: relative;
		h2 {
			.cormorant;
			text-transform: uppercase;
		}
		
		.concert-sponsor {
			margin-top: 10px;
			margin-bottom: 10px;
			img {
				display: block;
				max-width: 200px;
				margin-top: 10px;
			}
		}
		
	}
	
	.note-summary {
		strong {
			color: @tan-text;
		}
	}
	
	.action-buttons {
		display: inline-block;
		a {
			.hollow-button;	
			display: block;
		}
		.available-msg {
			display: block;
			margin-top: 10px;
		}
	}
	
	.tablet-and-smaller({
		
		.img {
			img {
				display: block;
				margin: auto;
				width: 300px;
			}
		}
		.content {
			margin-left: 15px;
			margin-right: 15px;
		}
	});
}

a.tan {
	color: @tan-text !important;
}
strong.tan {
	color: @tan-text !important;
}
strong.black {
	color: black;
}

.div-link {
	cursor: pointer;
}

.premium-program-advertisement, .standard-program-advertisement {
	margin-top: 40px;
	margin-bottom: 40px;
	img {
		display: block;
		width: 500px;
		max-width: 100%;
		margin: auto;
	}
}


.indented {
	margin-left: 30px;
	margin-right: 30px;
}
.standard-ads {

	max-width: 400px;
	margin: auto;
	padding: 20px;
	.tablet({
		float: right;
		padding: 20px 0 20px 20px;

	});
}

.indent-1 {
	margin-left: @page-side-padding;
}
.indent-2 {
	margin-left: 2 * @page-side-padding;
}
.indent-3 {
	margin-left: 3 * @page-side-padding;
}

.news-tile {
	display: grid;
	column-gap: 40px;
	row-gap: 40px;
	h2 {
		margin-bottom: 0;
	}
	.pub-date {
		margin-top: 0;
	}
	.pic {
		img {
			display: block;
			width: 100%;
		}
	}
	.content {
		h1, h2 {
			margin-top: 0;
		}
	}
	
	.tablet({
		grid-template-columns: auto 1fr;
		
		.pic {
			img {
				max-width: 300px;
			}
		}
	});
}
.news-tile + .news-tile {
	margin-top: 40px;
}

figure.flex-picture {

	margin: auto;
}

.chorale-featured {
	font-style: italic;
}

.show-gold {
	color: @tan-text !important;
}

.show-black {
	color: black !important;
}


