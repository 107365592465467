
.setup-width-definitions() {
	@container-width:			1100px;
	@tablet-width: 				900px;
	@tablet-small-width:        716px;
	@big-phone-width:			650px;
	@phone-width:               550px;
	@iphone-4-landscape-width:	480px;
	@iphone-4-width: 			320px;
	@page-side-padding: 			10px;
	@outside-page-padding: 			58px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@base-font-size:			7.6pt;

@grey-box-back: 			#eeeeee;
@grey-body-text:			#444444;

@grey-calendar-back: 		#f7f6f4;
@box-back: 					#f0eee9;


@yellow: 					#d7b24f;
@tan-text: 					#b3915a;
@tan-button-back: 			#b28f57;


@blue-background: 			#9dd3e6;

@salmon: 					#b88c84;




// font sizes

@page-h1-size: 				43;
@page-h2-size: 				30;
@page-h3-size: 				23;
@page-p-size: 				17;

@header-h1-size: 			84;



.source-sans-pro() {
	font-family: 'Source Sans Pro', sans-serif;
}

.open-sans() {
    font-family: 'Open Sans', sans-serif;
}

.cormorant {
	font-family: 'Cormorant', serif;
}

.jost {
	font-family: "Jost", sans-serif;
	font-optical-sizing: auto;
}

.menu-link() {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}	

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}


h1, h2, h3, h4, h5 {
	.link-inherit;
}

body {
	.open-sans;
    color: black;
}
